import styled from 'styled-components'
import { colors } from '../variables'

export const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.onBackground};
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: ${colors.background};
  border: 1px solid ${colors.border};
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-out, background-color 0.15s ease-out, border-color 0.15s ease-out,
    box-shadow 0.15s ease-out;
  outline: 0;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.onTheme};
    background-color: ${colors.theme};
    border-color: ${colors.theme};
    outline: 0;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0.25rem 0 ${colors.theme};
  }
`
