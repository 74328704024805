import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
  readonly block?: boolean
  readonly bold?: boolean
  readonly italic?: boolean
  readonly quote?: boolean
  readonly cite?: string
  readonly className?: string
  readonly children: React.ReactNode
}

export const En = ({ block, bold, italic, quote, cite, className, children }: Props) => {
  return block ? (
    quote ? (
      <Blockquote cite={cite} className={className} bold={bold} italic={italic}>
        {children}
      </Blockquote>
    ) : (
      <Div className={className} bold={bold} italic={italic}>
        {children}
      </Div>
    )
  ) : quote ? (
    <Q cite={cite} className={className} bold={bold} italic={italic}>
      {children}
    </Q>
  ) : (
    <Span className={className} bold={bold} italic={italic}>
      {children}
    </Span>
  )
}

type CommonProps = Pick<Props, 'bold' | 'italic'>

const commonStyle = css<CommonProps>`
  font-family: 'Roboto', sans-serif;
  font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};
  font-style: ${(props) => (props.italic ? 'italic' : 'inherit')};
`

const inlineStyle = css<CommonProps>`
  ${commonStyle};
  display: inline-block;
  margin: 0 0.25em;
  text-decoration: inherit;
`

const blockStyle = css<CommonProps>`
  display: block;
  margin: 0.75em 0;
  background-color: inherit;
`

const Span = styled.span<CommonProps>`
  ${inlineStyle};
`

const Q = styled.q<CommonProps>`
  ${inlineStyle};
  font-size: 0.9em;
`

const Div = styled.div<CommonProps>`
  ${blockStyle};
`

const Blockquote = styled.blockquote<CommonProps>`
  ${blockStyle};
  margin: 2.5em 4em;
  font-size: 0.9em;
`
