import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors } from '../variables'

/***
 * Tag assumes its surround element is declared as `display: flex; flex-direction: row`.
 */
export const Tag = styled(Link)`
  color: ${colors.onThemeLight};
  text-decoration: none;
  background: ${colors.themeLight};
  padding: 0.15em 0.3em;
  border-radius: 0.25rem;
  display: inline-block;

  & + & {
    margin-left: 0.5em;
  }
`
