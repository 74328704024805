import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import { colors, dimensions } from '../variables'
import { Header } from './Header'
import { Footer } from './Footer'

// See https://fonts.google.com/
const googleFontSourceURL = 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Roboto:ital@0;1&display=swap'

interface Props {
  readonly className?: string
  readonly children: React.ReactNode
}

export const BaseLayout = ({ className, children }: Props) => {
  // Defer loading Google fonts.
  useEffect(() => {
    const link: HTMLLinkElement | null = document.querySelector(
      `link[rel="preload"][as="font"][href="${googleFontSourceURL}"]`,
    )
    if (link != null) {
      link.rel = 'stylesheet'
    }
  }, [])

  return (
    <>
      <Helmet>
        <link href={googleFontSourceURL} rel="preload" as="font" />
      </Helmet>
      <GlobalStyle />
      <Container className={className}>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </Container>
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 18px;
    box-sizing: border-box;
    line-height: 1.25;
    -webkit-text-size-adjust: 100%;
  }

  // https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    color: ${colors.onBackground};
    background: ${colors.background};
    margin: 0;
    font-family: 'Noto Sans JP', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3 {
    font-weight: bold;
    margin: 2.5em 0 0.75em;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  p {
    margin: 0.75em 0;
  }

  a {
    color: ${colors.themeDark};
  }

  a:hover {
    filter: brightness(120%);
  }

  q:before,
  q:after {
    content: '';
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  max-width: ${dimensions.width.desktop};

  @media (max-width: ${dimensions.width.desktop}) {
    padding: 0 1rem;
  }
`

const Main = styled.main`
  width: 100%;
  flex-grow: 1;
  margin: 1rem 0 3rem;
`
