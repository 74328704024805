import React from 'react'
import styled from 'styled-components'
import { Callout } from './Callout'
import { En } from './En'
import { colors } from '../variables'

type PairType = [React.ReactChild, React.ReactChild | null]

interface Props {
  readonly children: ReadonlyArray<PairType>
}

export const Example = ({ children }: Props) => {
  return (
    <Callout>
      {children.map(([en, ja], index) => {
        // NOTE: This component assumes that `children` won't change after rendered, so `index` is used for `key`.
        return (
          <Pair key={index}>
            <SentenceEn>{en}</SentenceEn>
            {ja != null && <SentenceJa>{ja}</SentenceJa>}
          </Pair>
        )
      })}
    </Callout>
  )
}

const Pair = styled.div`
  & + & {
    margin-top: 3em;
  }
`

const SentenceEn = styled(En)`
  color: ${colors.onThemeLighter};
  font-size: 1.05rem;
  line-height: 1.4;

  > strong {
    font-weight: bolder;
  }
`

const SentenceJa = styled.div`
  color: ${colors.onThemeLighterLight};
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 1em 0 0 0;
`
