import React from 'react'
import styled from 'styled-components'
import { colors } from '../variables'

interface Props {
  readonly className?: string
  readonly children: React.ReactNode
}

export const Callout = ({ className, children }: Props) => {
  return <Container className={className}>{children}</Container>
}

const Container = styled.div`
  margin: 1.5em 0;
  padding: 1.75em 2em;
  position: relative;
  background-color: ${colors.themeLighter};

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.2em;
    height: 100%;
    background-color: ${colors.themeLight};
  }
`
