import React from 'react'
import styled from 'styled-components'
import { Update, WatchLater } from '@styled-icons/material-rounded'
import { colors } from '../variables'
import { formatDate } from '../utils'
import { Tag } from './Tag'

interface Props {
  readonly tags: string[]
  readonly datePublished: Date
  readonly dateModified?: Date
}

export const PostMeta = ({ tags, datePublished, dateModified }: Props) => {
  return (
    <Container>
      <Times>
        <Time dateTime={datePublished.toISOString()}>
          <WatchLater size="1rem" />
          {formatDate(datePublished)}
        </Time>
        {dateModified != null && (
          <Time dateTime={dateModified.toISOString()}>
            <Update size="1rem" />
            {formatDate(dateModified)}
          </Time>
        )}
      </Times>
      <Tags>
        {tags.map((tag) => {
          return (
            <Tag key={tag} to={`/tags/${tag}/`}>
              {tag}
            </Tag>
          )
        })}
      </Tags>
    </Container>
  )
}

const Container = styled.div`
  margin: 1rem 0;
`

const Times = styled.div`
  display: flex;
  align-items: center;
`

const Time = styled.time`
  display: flex;
  align-items: center;
  color: ${colors.onBackgroundLight};

  & + & {
    margin-left: 0.75em;
  }
`

const Tags = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
