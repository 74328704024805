import React from 'react'

interface Props {
  readonly to: string
  readonly className?: string
  readonly children: React.ReactNode
}

export const ExternalLink = ({ to, className, children }: Props) => {
  return (
    <a href={to} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}
