import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface Props {
  readonly src: string
  readonly alt?: string
  readonly width?: number | string
  readonly height?: number | string
  readonly className?: string
  readonly style?: CSSProperties
  readonly loading?: 'lazy' | 'eager'
}

export const Image = ({ src, alt, width, height, className, style, loading = 'lazy' }: Props) => {
  return <Img src={src} alt={alt} width={width} height={height} className={className} style={style} loading={loading} />
}

const Img = styled.img`
  display: block;
  border: none;
  max-width: 100%;
  margin: 1em auto 2em;
  object-fit: contain;
`
