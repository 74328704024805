export const colors = {
  theme: 'hsl(20, 100%, 50%)',
  onTheme: 'hsl(0,0%,100%)',
  themeDark: 'hsl(20, 100%, 36%)',
  themeLight: 'hsl(20, 100%, 75%)',
  themeLighter: 'hsl(20, 100%, 99%)',
  onThemeLight: 'hsl(20, 10%, 3%)',
  onThemeLighter: 'hsl(20, 10%, 3%)',
  onThemeLighterLight: 'hsl(20, 10%, 30%)',
  background: 'hsl(20, 0%,100%)',
  onBackground: 'hsl(20, 10%, 20%)',
  onBackgroundLight: 'hsl(20, 0%, 50%)',
  onBackgroundDark: 'hsl(20, 10%, 8%)',
  border: 'hsl(20, 0%, 60%)',
}

export const siteMetaData = {
  siteName: 'Struggle with English',
  baseURL: 'https://www.struggle-with-english.com/',
  description:
    '英語に近道はない、という信念で英語学習に役立つ情報を発信していきます。' +
    '特にこのサイトでは英語に関する情報をなるべく理屈で記載するようにしています。' +
    'これまでなんとなく感覚で英語に触れてきたけれど、なぜその表現が適切なのかうまく説明できない、' +
    'というときにぜひ活用してみてください',
}

export const dimensions = {
  width: {
    thumbnail: '330px',
    mobile: '375px',
    desktop: '1080px',
  },
  height: {
    thumbnail: '200px',
  },
}
