import React from 'react'
import styled from 'styled-components'
import { siteMetaData } from '../variables'
import { Link } from 'gatsby'

interface Props {
  readonly className?: string
}

export const Footer = ({ className }: Props) => {
  return (
    <Container className={className}>
      <About>英語に近道はない、という信念で英語学習に役立つ情報を発信していきます。</About>
      <Nav>
        <NavLink to="/about/">プロフィール</NavLink>
        <NavLink to="/terms/">利用規約</NavLink>
        <NavLink to="/privacy/">プライバシー</NavLink>
        <NavLink to="/contact/">お問い合わせ</NavLink>
      </Nav>
      <Copyright>
        © {new Date().getFullYear()} {siteMetaData.siteName}
      </Copyright>
    </Container>
  )
}

const Container = styled.footer`
  display: block;
  width: 100%;
  padding-bottom: 0.5rem;
`

const About = styled.div`
  display: block;
  padding: 0 1em;
  text-align: center;
`

const Nav = styled.nav`
  margin: 0.5rem auto;
  width: max-content;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const NavLink = styled(Link)`
  display: block;
  margin: 0.5rem;
`

const Copyright = styled.div`
  font-size: 0.9rem;
  text-align: center;
  margin-top: 1rem;
`
