import React from 'react'
import styled from 'styled-components'
import { colors, dimensions } from '../variables'
import { ArrowBack, ArrowForward } from '@styled-icons/material-rounded'
import { Article } from '../types'
import { Link } from 'gatsby'

interface Props {
  readonly next: Article | null
  readonly previous: Article | null
}

export const NextAndPrevious = ({ next, previous }: Props) => {
  return (
    <Container>
      {previous != null ? (
        <Pager to={previous.fields.path} place="left">
          <Label>次の投稿</Label>
          <ArrowBack size="1rem" />
          {previous.frontmatter.title}
        </Pager>
      ) : (
        <span />
      )}
      {next != null ? (
        <Pager to={next.fields.path} place="right">
          <Label>前の投稿</Label>
          {next.frontmatter.title}
          <ArrowForward size="1rem" />
        </Pager>
      ) : (
        <span />
      )}
    </Container>
  )
}

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 2rem auto;
  max-width: 100%;
  border-top: 1px solid ${colors.border};

  @media (max-width: ${dimensions.width.desktop}) {
    display: block;
  }
`

const Pager = styled(Link)<{ readonly place: 'left' | 'right' }>`
  display: block;
  text-align: ${({ place }) => place};
  text-decoration: none;
  max-width: 48%;

  @media (max-width: ${dimensions.width.desktop}) {
    max-width: unset;

    & + & {
      margin-top: 1.5em;
    }
  }
`

const Label = styled.div`
  display: block;
  color: ${colors.onBackgroundLight};
  margin-bottom: 0.5em;
`
