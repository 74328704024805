import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, dimensions } from '../variables'
import { formatDate } from '../utils'
import { Tag } from './Tag'

const TRUNCATE_LENGTH = 48

interface Props {
  readonly url: string
  readonly thumbnail: string
  readonly title: string
  readonly bodyPrefix: string
  readonly tags: string[]
  readonly publishedDate: string
  readonly className?: string
}

export const ArticleSummary = ({ url, thumbnail, title, bodyPrefix, tags, publishedDate, className }: Props) => {
  return (
    <Container className={className}>
      <A to={url}>
        <Img src={thumbnail} alt={title} />
      </A>
      <H2>
        <A to={url}>{title}</A>
      </H2>
      <BodyPrefix>
        <A to={url}>
          {bodyPrefix.slice(0, TRUNCATE_LENGTH)}
          <span style={{ fontFamily: 'Roboto' }}>…</span>
        </A>
      </BodyPrefix>
      <DateContainer>
        {/* TIME */}
        <A to={url}>
          <time dateTime={publishedDate}>{formatDate(new Date(publishedDate))}</time>
        </A>
      </DateContainer>
      <Tags>
        {tags.map((tag) => (
          <Tag key={tag} to={`/tags/${tag}/`}>
            {tag}
          </Tag>
        ))}
      </Tags>
    </Container>
  )
}

const Container = styled.div`
  display: block;
  width: ${dimensions.width.thumbnail};
  max-width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 1rem;
`

const A = styled(Link)`
  font-weight: normal;
  color: ${colors.onBackgroundLight};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: ${colors.onBackgroundDark};
  }
`

const Img = styled.img`
  display: block;
  width: 100%;
  height: ${dimensions.height.thumbnail};
  object-fit: cover;
`

const H2 = styled.h2`
  font-weight: inherit;
  margin: 1rem 0 0 0;

  > a {
    color: inherit;
  }
`

const BodyPrefix = styled.div`
  margin: 1rem 0 0 0;
`

const DateContainer = styled.div`
  margin: 1rem 0 0 0;
`

const Tags = styled.div`
  margin: 1rem 0 0 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
