interface Args {
  readonly headline: string
  readonly description: string
  readonly image?: URL
  readonly datePublished: Date
  readonly dateModified?: Date
}

export const googleStructuredData = ({ headline, description, image, datePublished, dateModified }: Args) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline,
    description,
    image,
    datePublished,
    dateModified,
  })
}

export const formatDate = (date: Date): string => `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
