import React from 'react'
import styled from 'styled-components'
import logo from './logo.svg'
import { Link } from 'gatsby'

export const Header = () => {
  return (
    <Container>
      <nav>
        <LogoLink to="/">
          <img alt="Struggle with English" src={logo} />
        </LogoLink>
      </nav>
    </Container>
  )
}

const Container = styled.header`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5rem;
`

const LogoLink = styled(Link)`
  display: block;
`
