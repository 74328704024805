import { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../variables'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url'
}

const style = css`
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.onBackground};
  background-color: ${colors.background};
  background-clip: padding-box;
  border: 1px solid ${colors.border};
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-out, box-shadow 0.15s ease-out;

  &:focus {
    border-color: ${colors.themeLight};
    outline: 0;
    box-shadow: 0 0 0.25rem 0 ${colors.themeLight};
  }
`

export const TextField = styled.input<InputProps>`
  ${style};
}
`

export const ParagraphField = styled.textarea`
  ${style};
  resize: vertical;
}
`
