import React from 'react'
import styled from 'styled-components'
import type { Article } from '../types'
import { BaseLayout } from './BaseLayout'
import { ArticleSummary } from './ArticleSummary'
import { dimensions } from '../variables'

interface Props {
  readonly articles: Article[]
}

export const ArticleSummaries = ({ articles }: Props) => {
  return (
    <BaseLayout>
      <ListContainer>
        {articles.map((article) => (
          <ArticleSummary
            key={article.fields.path}
            url={article.fields.path}
            thumbnail={article.frontmatter.thumbnail}
            title={article.frontmatter.title}
            bodyPrefix={article.frontmatter.bodyPrefix}
            tags={article.frontmatter.tags}
            publishedDate={article.frontmatter.publishedDate}
          />
        ))}
      </ListContainer>
    </BaseLayout>
  )
}

const breakPoints = {
  first: `${parseInt(dimensions.width.thumbnail) * 2 + 16}px`,
  second: `${parseInt(dimensions.width.thumbnail) * 3 + 16 * 2}px`,
}

const ListContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(1, ${dimensions.width.thumbnail});
  gap: 16px;

  // For too small displays
  @media (max-width: ${dimensions.width.thumbnail}) {
    display: block;
  }

  @media (min-width: ${breakPoints.first}) {
    grid-template-columns: repeat(2, ${dimensions.width.thumbnail});
  }

  @media (min-width: ${breakPoints.second}) {
    grid-template-columns: repeat(3, ${dimensions.width.thumbnail});
  }
`
